import React, { FC } from 'react'
import AppLogoBackOffice from '../../assets/AppLogoBackOffice'
import AppLogoDrive from '../../assets/AppLogoDrive'
import AppLogoOrg from '../../assets/AppLogoOrg'
import AppLogoProcess from '../../assets/AppLogoProcess'
import AppLogoReport from '../../assets/AppLogoReport'
import { Link } from 'react-router-dom'
import { Organization } from '../../model/Organizations'
import AppLogoStudio from '../../assets/AppLogoStudio'

type AppCode = 's-backoffice' | 's-drive' | 's-organization' | 's-process' | 's-report' | 's-studio'
type AppsPanelProps = {
  org: Organization
}
const appLogoMap: Record<AppCode, React.FC> = {
  's-backoffice': AppLogoBackOffice,
  's-drive': AppLogoDrive,
  's-organization': AppLogoOrg,
  's-process': AppLogoProcess,
  's-report': AppLogoReport,
  's-studio': AppLogoStudio,
}

const AppsPanel: FC<AppsPanelProps> = ({ org }) => {
  return (
    <div className='py-3 bg-white rounded-[14px] flex flex-wrap justify-center shadow-[1px_0px_18px_-4px_rgba(0,0,0,0.2)] px-2 mx-3 gap-x-10'>
      {org.appAccess?.map((app, index) => {
        const AppLogo = appLogoMap[app.code as AppCode]
        const appData = org.apps.find((a) => a.code === app.code)

        if (!AppLogo || !appData) return null

        return (
          <Link key={index} to={`${appData.url}/${org.organizationCode}`}>
            <AppLogo />
          </Link>
        )
      })}
    </div>
  )
}

export default AppsPanel
